import dayjs from 'dayjs'

export const getUtcMonth = (date: string, fallback: string = null) => {
  if (!date) return fallback
  return dayjs(date).utc().format('MM/YYYY')
}

export const getUtcDate = (date: string, fallback: string = null) => {
  if (!date) return fallback
  return dayjs(date).utc().format('MM/DD/YYYY')
}
