import { Divider, Modal, Tooltip } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'

import MonthInput from '../../MonthInput/MonthInput'
import TextInput from '../../TextInput/TextInput'

const EditUnemploymentModal = ({
  opened,
  close,
  experiences,
  setExperiences,
  currentIndex,
  handleSave,
  handleCancel,
}) => {
  const item = experiences[currentIndex]

  const updateExperience = (key, value) => {
    const tempObj = { ...experiences[currentIndex] }
    tempObj[key] = value
    const tempArray = [...experiences]
    tempArray[currentIndex] = tempObj
    setExperiences(tempArray)
  }

  const handleSubmit = () => {
    handleSave()
    close()
  }

  const onCancel = () => {
    handleCancel()
    close()
  }

  const isEmpty = (field) => {
    return field === undefined || field === '' || field === null
  }

  const emptyRequiredFields = () => {
    const isMissingRequiredFields =
      isEmpty(item.startDate) ||
      isEmpty(item.endDate) ||
      isEmpty(item.unemploymentReason)

    return isMissingRequiredFields
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
      size="50%"
      centered
      title={'Edit Unemployment'}
    >
      <div className="flex flex-col gap-4">
        <div className="text-sm font-semibold text-doubleNickel-gray-900">
          Please fill out the following fields
        </div>

        <div className="grid grid-cols-2 gap-3">
          <MonthInput
            label={'Start Date'}
            classNames={{
              root: 'flex-1',
              label: 'text-doubleNickel-gray-700 text-sm',
            }}
            required
            editing={true}
            _maxDate={item.endDate || new Date().toISOString()}
            value={item.startDate}
            handleChange={(value) => updateExperience('startDate', value)}
          />
          <MonthInput
            label={'End Date'}
            classNames={{
              root: 'flex-1',
              label: 'text-doubleNickel-gray-700 text-sm',
            }}
            required
            editing={item.endDate ? true : false}
            value={item.endDate}
            handleChange={(value) => updateExperience('endDate', value)}
            _minDate={item.startDate}
            _maxDate={new Date().toISOString()}
          />
          <div className="col-span-2">
            <TextInput
              label="Unemployment Reason"
              placeholder="Enter Unemployment Reason"
              editing={true}
              required
              value={item.unemploymentReason}
              handleChange={(value) =>
                updateExperience('unemploymentReason', value)
              }
            />
          </div>
        </div>

        <Divider />
        <div className="flex flex-row items-center justify-between gap-2">
          <Button
            variant="outline"
            text={'Cancel'}
            onClick={() => onCancel()}
            className="flex-1 text-doubleNickel-gray-700"
          />
          <Tooltip
            label={
              emptyRequiredFields() ? 'Please fill out required fields' : ''
            }
            position="top"
            disabled={!emptyRequiredFields()}
          >
            <Button
              text={'Submit'}
              disabled={emptyRequiredFields()}
              onClick={() => handleSubmit()}
              className="flex-1"
            />
          </Tooltip>
        </div>
      </div>
    </Modal>
  )
}

export default EditUnemploymentModal
