import { useState } from 'react'

import { ActionIcon, Divider, Select, Tooltip } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { useDisclosure } from '@mantine/hooks'
import dayjs from 'dayjs'

import { useQuery, useMutation } from '@redwoodjs/web'

import DocumentPreview from 'src/components/Overlays/DocumentPreview/DocumentPreview'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_APPLICATION_BY_APPLICANT_ID,
  UPDATE_APPLICATION,
} from 'src/graphql/application.graphql'
import IconShareOpen from 'src/icons/IconShareOpen'

import AccidentsCard from './components/AccidentsCard'
import AddressInformationCard from './components/AddressInformationCard'
import ConsentCard from './components/ConsentCard'
import EmploymentCard from './components/EmploymentCard/EmploymentCard'
import EmploymentSummaryCard from './components/EmploymentSummaryCard/EmploymentSummary'
import EquipmentCard from './components/EquipmentCard/EquipmentCard'
import JobInformationCard from './components/JobInformationCard/JobInformationCard'
import LicenseInformationCard from './components/LicenseInformationCard/LicenseInformationCard'
import ApplicantSharingModal from './components/Modals/ApplicantSharingModal'
import PersonalInformationCard from './components/PersonalInformationCard'
import TwicInformationCard from './components/TwicInformationCard/TwicInformationCard'
import {
  getAddressInformation,
  getJobInformation,
  getLicenseInformation,
  getTwicInformation,
  getPersonalInformation,
  getAccidentInformation,
  getOwnerOperatorEquipment,
} from './generateData'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const ApplicationTab = ({ applicantId, onRender }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [updatedItems, setUpdatedItems] = useState({})
  const [documentForPreview, setDocumentForPreview] = useState(null)

  const { data: applicationResponse } = useQuery(
    GET_APPLICATION_BY_APPLICANT_ID,
    {
      variables: {
        applicantId,
      },
      onError,
    }
  )

  const [updateApplication] = useMutation(UPDATE_APPLICATION, {
    variables: {
      id: applicationResponse?.applicationByApplicantId?.applicationId,
      input: { ...updatedItems },
    },
    refetchQueries: [
      {
        query: GET_APPLICATION_BY_APPLICANT_ID,
        variables: {
          applicantId,
        },
      },
      ...onRender,
    ],
    onCompleted: () => {
      toast('Application updated successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong', 'error')
    },
  })

  const updateStatus = (value) => {
    updateApplication({
      variables: {
        id: applicationResponse?.applicationByApplicantId?.applicationId,
        input: {
          status: value,
        },
      },
    })
  }

  const handleUpdateApplication = async (value) => {
    return await updateApplication({
      variables: {
        id: applicationResponse?.applicationByApplicantId?.applicationId,
        input: {
          ...value,
        },
      },
    })
  }

  const handlePreview = (consent) => {
    setDocumentForPreview(consent.document)
  }

  if (!applicationResponse) return null

  const application = applicationResponse?.applicationByApplicantId || {}

  const employmentExperiences =
    application?.employmentExperiences?.map((experience) => ({
      ...experience,
      startDate: experience?.startDate
        ? new Date(experience.startDate)
        : undefined,
      endDate: experience?.endDate ? new Date(experience.endDate) : undefined,
    })) || []

  return (
    <DatesProvider settings={{ timezone: 'UTC' }}>
      <div className="flex h-full flex-col gap-5">
        {opened && (
          <ApplicantSharingModal
            isModalOpen={opened}
            onModalClose={close}
            applicationId={application.applicationId}
          />
        )}
        {documentForPreview ? (
          <DocumentPreview
            documentId={documentForPreview.documentId}
            applicantId={applicantId}
            onBack={() => setDocumentForPreview(null)}
            showActions={false}
          />
        ) : (
          <div className="flex h-[88%] flex-col gap-4 overflow-auto">
            <div>
              <div className="flex flex-row items-center gap-4 py-2">
                <div className="text-lg font-semibold text-doubleNickel-black">
                  Application
                </div>
                <Select
                  value={application?.status}
                  onChange={(value) => updateStatus(value)}
                  data={[
                    { label: 'Not Started', value: 'NOT_STARTED' },
                    { label: 'In Progress', value: 'IN_PROGRESS' },
                    { label: 'Pending Review', value: 'PENDING_REVIEW' },
                    { label: 'Completed', value: 'COMPLETED' },
                  ]}
                  classNames={{
                    root: 'w-40',
                    option: 'text-xs',
                    input: `rounded-lg text-xs font-semibold ${
                      application?.status === 'IN_PROGRESS' &&
                      'bg-doubleNickel-brand-25 border border-doubleNickel-brand-500 text-doubleNickel-brand-500 '
                    }
                ${
                  application?.status === 'PENDING_REVIEW' &&
                  'bg-doubleNickel-warning-50 border border-doubleNickel-warning-500 text-doubleNickel-warning-600 '
                }
                ${
                  application?.status === 'COMPLETED' &&
                  'bg-doubleNickel-success-50 border border-doubleNickel-success-600 text-doubleNickel-success-600 '
                }`,
                  }}
                  allowDeselect={false}
                />
                <div className="text-xs font-medium text-doubleNickel-gray-600">
                  Last updated:{' '}
                  {dayjs(application?.updatedAt).format(
                    'HH:mm:ss on MM/DD/YYYY'
                  )}
                </div>
                <Tooltip label="Share">
                  <ActionIcon
                    variant="subtle"
                    className="ml-auto"
                    onClick={open}
                  >
                    <IconShareOpen className="max-h-5 fill-none stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Tooltip>
              </div>
              <Divider />
            </div>
            <div className="flex flex-col gap-4 overflow-auto">
              <JobInformationCard
                {...getJobInformation(application)}
                handleUpdateApplication={handleUpdateApplication}
              />
              <PersonalInformationCard
                {...getPersonalInformation(application)}
                handleUpdateApplication={handleUpdateApplication}
              />
              <div className="grid grid-cols-4 gap-3">
                <div className="col-span-3">
                  <LicenseInformationCard
                    {...getLicenseInformation(application)}
                    handleUpdateApplication={handleUpdateApplication}
                  />
                </div>
                <div className="col-span-1">
                  <TwicInformationCard
                    {...getTwicInformation(application)}
                    handleUpdateApplication={handleUpdateApplication}
                  />
                </div>
              </div>
              {application?.employmentExperiences.length > 0 && (
                <EmploymentSummaryCard application={application} />
              )}
              <EmploymentCard
                employmentExperiences={employmentExperiences}
                handleUpdateApplication={handleUpdateApplication}
              />
              <AddressInformationCard
                {...getAddressInformation(application)}
                handleUpdateApplication={handleUpdateApplication}
              />
              <AccidentsCard
                {...getAccidentInformation(application)}
                handleUpdateApplication={handleUpdateApplication}
              />
              <EquipmentCard
                {...getOwnerOperatorEquipment(application)}
                handleUpdateApplication={handleUpdateApplication}
              />
              <ConsentCard
                consents={application?.consents}
                onPreview={handlePreview}
              />
            </div>
          </div>
        )}
      </div>
    </DatesProvider>
  )
}

export default ApplicationTab
