import { NetworkStatus } from '@apollo/client'
import { Loader } from '@mantine/core'

import { cn } from 'src/utils'

import { ActivityLogTimelineRow } from './ActivityLogTimelineRow'

const ActivityLogTimeline = ({
  activityLogsResponse,
  networkStatus,
  refParent,
}) => {
  const groupActivityLogsByDate = (activityLogs) => {
    if (!activityLogs) {
      return {}
    }
    return activityLogs.reduce((acc, activityLog) => {
      const date = new Date(activityLog.createdAt).toDateString()
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(activityLog)
      return acc
    }, {})
  }

  const groupedActivityLogs = groupActivityLogsByDate(
    activityLogsResponse?.activityLogs.items
  )

  const groupedActivityLogsArray = Object.keys(groupedActivityLogs).map(
    (date) => {
      return {
        date,
        activityLogs: groupedActivityLogs[date],
      }
    }
  )

  return (
    <div className="flex h-full flex-col gap-2 overflow-hidden">
      <div
        className={cn(
          'grid grid-cols-10 gap-2 bg-doubleNickel-gray-50 p-2 text-xs font-semibold text-doubleNickel-gray-700'
        )}
      >
        <div>Date</div>
        <div>Time</div>
        <div className="col-span-3">Details</div>
        <div className="col-span-3">Description</div>
        <div className="col-span-2">Tags</div>
      </div>
      <div className="h-full overflow-auto">
        {groupedActivityLogsArray.map((activityLogGroup) => (
          <div key={activityLogGroup.date}>
            {activityLogGroup.activityLogs.map((activityLog) => (
              <ActivityLogTimelineRow
                key={activityLog.createdAt}
                activityLog={activityLog}
              />
            ))}
          </div>
        ))}
        {networkStatus === NetworkStatus.fetchMore && (
          <Loader size="sm" className="flex w-full flex-row justify-center" />
        )}
        {activityLogsResponse?.activityLogs.items.length <
          activityLogsResponse?.activityLogs.totalCount && (
          <div ref={refParent} className="opacity-0">
            Inview placeholder
          </div>
        )}
      </div>
    </div>
  )
}

export default ActivityLogTimeline
