import { useState } from 'react'

import { ActionIcon, Menu } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'
import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconEdit from 'src/icons/IconEdit'
import {
  CountryConfig,
  CountryConfigItem,
  findIssuingCountry,
} from 'src/lib/country.utils'
import {
  formatInternationalPhoneNumber,
  removeCountryCode,
  unformatPhoneNumber,
} from 'src/lib/phone.utils'
import { unformatSsn } from 'src/lib/ssn.utils'

import DateInput from '../../../../../components/Inputs/DateInput/DateInput'

import PasswordInput from './PasswordInput/PasswordInput'
import TextInput from './TextInput/TextInput'

const PersonalInformationCard = ({
  values: appValues = [],
  handleUpdateApplication,
}) => {
  const [values, setValues] = useState({ ...appValues[0] })
  const [editing, setEditing] = useState(false)
  const existingIssuingCountry = values?.issuingCountry
    ? findIssuingCountry(values.issuingCountry)
    : undefined
  const [issuingCountryConfig, setIssuingCountry] = useState<CountryConfigItem>(
    existingIssuingCountry || CountryConfig.US
  )

  const handleCancel = () => {
    setValues({ ...appValues[0] })
    setEditing(false)
  }

  const handleSave = () => {
    //Update other personal information:
    let updates = {}
    updates = {
      ...(values.dob !== undefined && { dob: values.dob }),
      ...(values.ssn !== undefined && { ssn: unformatSsn(values.ssn) }),
      ...(values.issuingCountry !== undefined && {
        issuingCountry: values.issuingCountry,
      }),
      ...(values.phone !== undefined && {
        phone: unformatPhoneNumber(values.phone),
      }),
      ...(values.firstName !== undefined && { firstName: values.firstName }),
      ...(values.middleName !== undefined && { middleName: values.middleName }),
      ...(values.lastName !== undefined && { lastName: values.lastName }),
      ...(values.email !== undefined && { email: values.email }),
    }
    handleUpdateApplication(updates).then((res) => {
      if (!res.errors) {
        setEditing(false)
      }
    })
  }

  const validatePhone = (phone) => {
    if (!phone) return undefined

    const phoneLength = unformatPhoneNumber(removeCountryCode(phone)).length
    if (phoneLength !== 10) {
      return 'Number must be 10 digits'
    }
    return undefined
  }

  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-4`}
    >
      <div className=" text-md flex flex-row items-center justify-between font-semibold text-doubleNickel-black">
        Personal Information
        {!editing && (
          <ActionIcon
            className="h-8 w-8 border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm hover:bg-doubleNickel-gray-200"
            onClick={() => setEditing(true)}
          >
            <IconEdit className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
          </ActionIcon>
        )}
      </div>
      <div className={`grid grid-cols-4 gap-3`}>
        <TextInput
          label="First Name"
          editing={editing}
          value={values.firstName}
          handleChange={(value) => setValues({ ...values, firstName: value })}
        />

        <TextInput
          label="Middle Name"
          editing={editing}
          value={values.middleName}
          handleChange={(value) => setValues({ ...values, middleName: value })}
        />
        <TextInput
          label="Last Name"
          editing={editing}
          value={values.lastName}
          handleChange={(value) => setValues({ ...values, lastName: value })}
        />
        <DateInput
          label="Date Of Birth"
          editing={editing}
          value={values.dob}
          onChange={(dob) => {
            setValues({ ...values, dob: dob })
          }}
        />
        <PasswordInput
          label={issuingCountryConfig.abbreviation}
          editing={editing}
          value={values.ssn}
          handleChange={(value) => {
            setValues({
              ...values,
              ssn: value,
            })
          }}
          leftSectionWidth={editing ? '60px' : undefined}
          leftSectionPointerEvents={'all'}
          leftSection={
            editing ? (
              <Menu shadow="md">
                <Menu.Target>
                  <div className="flex flex-row items-center gap-1 text-nowrap px-2 text-sm font-medium text-doubleNickel-gray-800 hover:cursor-pointer">
                    {issuingCountryConfig.code}
                    <IconChevronDown className="h-4 fill-none stroke-doubleNickel-gray-400" />
                  </div>
                </Menu.Target>
                <Menu.Dropdown>
                  {Object.values(CountryConfig).map((config) => (
                    <Menu.Item
                      key={config.code}
                      onClick={() => setIssuingCountry(config)}
                    >
                      {config.code}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            ) : null
          }
        />
        {editing ? (
          <PhoneInput
            label="Phone"
            editing={editing}
            value={values.phone}
            onChange={(value) => setValues({ ...values, phone: value })}
            error={validatePhone(values.phone)}
          />
        ) : (
          <StyledReadOnlyInput
            label="Phone"
            value={formatInternationalPhoneNumber(values.phone)}
          />
        )}
        <TextInput
          label="Email"
          editing={editing}
          value={values.email}
          handleChange={(value) => setValues({ ...values, email: value })}
        />
      </div>
      {editing && (
        <div className="flex flex-row gap-4">
          <Button
            text="Cancel"
            variant="outline"
            className="ml-auto"
            onClick={handleCancel}
          />
          <Button
            text="Save"
            onClick={handleSave}
            disabled={validatePhone(values.phone) !== undefined}
          />
        </div>
      )}
    </div>
  )
}

export default PersonalInformationCard
