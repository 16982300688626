import { memo } from 'react'

import { Radio } from '@mantine/core'
import dayjs from 'dayjs'

import StyledReadOnlyInput from 'src/components/Inputs/StyledReadOnlyInput/StyledReadOnlyInput'
import { formatAddress } from 'src/lib/address.utils'
import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'

import { getUtcDate } from '../utils'

const QuestionRow = ({ editing = false, text, value }) => {
  const groupValue =
    value !== undefined && value !== null ? value.toString() : undefined

  return (
    <div className="flex flex-row ">
      <div className="w-[80%] text-sm font-medium text-doubleNickel-gray-700">
        {text}
      </div>
      {editing ? (
        <Radio.Group className="ml-auto" value={groupValue}>
          <div className="flex flex-row gap-6">
            <Radio
              value="true"
              label="Yes"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
            <Radio
              value="false"
              label="No"
              size="xs"
              classNames={{
                label: 'text-sm',
                radio: 'checked:bg-doubleNickel-brand-500',
              }}
            />
          </div>
        </Radio.Group>
      ) : (
        <div className="ml-auto text-sm font-medium text-doubleNickel-gray-700">
          {value === null || value === undefined ? '-' : value ? 'Yes' : 'No'}
        </div>
      )}
    </div>
  )
}

const EmploymentDetails = memo(function EmploymentDetails({
  employmentExperience,
}: {
  employmentExperience: any
}) {
  return employmentExperience.isUnemployment ? (
    <div className="grid grid-cols-2 gap-y-3 pt-5">
      <StyledReadOnlyInput
        label="Start Date"
        value={getUtcDate(employmentExperience.startDate)}
      />
      <StyledReadOnlyInput
        label="End Date"
        value={getUtcDate(employmentExperience.endDate)}
      />
      <div className="col-span-2">
        <StyledReadOnlyInput
          label="Unemployment Reason"
          value={employmentExperience.unemploymentReason}
        />
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-3 pt-5">
      <div className="grid grid-cols-3 gap-y-3">
        <StyledReadOnlyInput
          label="Dot Number"
          value={employmentExperience.dotNumber}
        />
        <StyledReadOnlyInput
          label="Contact Name"
          value={employmentExperience.contactName}
        />
        <StyledReadOnlyInput
          label="Phone"
          value={formatInternationalPhoneNumber(employmentExperience.phone)}
        />
        <StyledReadOnlyInput
          label="Fax Number"
          value={formatInternationalPhoneNumber(employmentExperience.faxNumber)}
        />
        <StyledReadOnlyInput label="Email" value={employmentExperience.email} />
      </div>
      <StyledReadOnlyInput
        label="Address"
        value={formatAddress(employmentExperience.address)}
      />
      <StyledReadOnlyInput
        label="Reason for leaving"
        value={employmentExperience.reasonForLeaving}
      />
      <QuestionRow
        text="Was this driver terminated?"
        value={employmentExperience.isDriverTerminated}
      />
      <QuestionRow
        text="Was the driver subject to FMCSRs while employed?"
        value={employmentExperience.isDriverSubjectToFMCSRs}
      />
      <QuestionRow
        text="Was the job designated as a safety-sensitive function in any Department of Transportation-regulated mode subject to alcohol and controlled substances testing as required by 49 CFR, part 40?"
        value={
          employmentExperience.isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        }
      />
      <QuestionRow
        text="Contact Employer?"
        value={employmentExperience.shouldContactEmployer}
      />
    </div>
  )
})

export default EmploymentDetails
