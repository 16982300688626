import { useContext } from 'react'

import { useHover } from '@mantine/hooks'
import dayjs from 'dayjs'

import Tag from 'src/components/DataDisplay/Tag/Tag'
import { CallStatus } from 'src/context/PhoneDeviceContext'
import { RecruitersContext } from 'src/context/RecruitersContext'
import { ActivityLogType } from 'src/graphql/types/activityLogs'
import IconDocument from 'src/icons/IconDocument'
import IconMessage from 'src/icons/IconMessage'
import IconNote from 'src/icons/IconNote'
import IconPhone from 'src/icons/IconPhone'
import { buildFullName } from 'src/lib/formatters'
import { replaceRecruitersIdWithName } from 'src/lib/recruiters.utils'
import { cn } from 'src/utils'

import { mapTypeToColor } from './mapTypeToColor'

const CallTitle = ({ activityLog }) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isInboundCall = activityLog.callDirection === 'INBOUND'

  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  let label = isOutboundCall ? 'Outgoing call' : 'Inbound call'
  if (isMissedCall) {
    label = `Missed ${isInboundCall ? 'inbound' : 'outgoing'} call`
  }
  return (
    <div className="flex flex-col gap-1 text-xs text-doubleNickel-gray-500">
      <div className="flex gap-2">
        <div
          className={
            isMissedCall
              ? 'text-doubleNickel-error-700'
              : mapTypeToColor(ActivityLogType.CALL)
          }
        >
          {label}
        </div>
      </div>
    </div>
  )
}

const CallDescription = ({ activityLog }) => {
  const isOutboundCall = activityLog.callDirection === 'OUTBOUND'
  const isVirtualCall = activityLog.callType === 'BLANDAI'
  const isLeftVoiceMail = activityLog.callStatus === CallStatus.LEFT_VOICEMAIL
  const callDurationInSeconds = dayjs(activityLog.endCallDate).diff(
    dayjs(activityLog.startCallDate),
    'seconds'
  )
  const callInMinutes = Math.floor(callDurationInSeconds / 60)
  const remindingSeconds = callDurationInSeconds % 60
  const voiceMailTitle = activityLog.voiceMailTemplate?.title

  const isMissedCall =
    activityLog.callStatus === CallStatus.NO_ANSWER ||
    activityLog.callStatus === CallStatus.BUSY ||
    activityLog.callStatus === CallStatus.LEFT_VOICEMAIL

  return (
    <div className="text-xs text-doubleNickel-gray-500">
      <div>
        {!voiceMailTitle &&
          isOutboundCall &&
          !isMissedCall &&
          !isNaN(callInMinutes) && (
            <p>
              {callInMinutes} minutes, {remindingSeconds} seconds
            </p>
          )}
        {isLeftVoiceMail && voiceMailTitle && (
          <p>Voicemail: {voiceMailTitle}</p>
        )}
      </div>
      <div>
        {!isVirtualCall && (
          <p>Recruiter: {buildFullName(activityLog.employee)}</p>
        )}
      </div>
    </div>
  )
}

export const ActivityLogTimelineRow = ({ activityLog }) => {
  const { hovered, ref } = useHover()
  const recruiters = useContext(RecruitersContext)
  const isCall = activityLog.type === ActivityLogType.CALL
  const isVirtualCall =
    activityLog.callType === 'BLANDAI' &&
    activityLog.type === ActivityLogType.CALL
  const isManualRegistered =
    activityLog.callStatus === null && activityLog.type === ActivityLogType.CALL

  return (
    <div
      ref={ref}
      className={cn(
        'grid min-h-10 cursor-pointer grid-cols-10 items-center gap-2 px-2 py-1 text-xs text-doubleNickel-gray-500 hover:bg-doubleNickel-gray-100'
      )}
      key={activityLog.createdAt}
    >
      {/* Date */}
      <div>{dayjs(activityLog.createdAt).format('MMM DD, YYYY')}</div>

      {/* Time */}
      <div>{dayjs(activityLog.createdAt).format('hh:mm A')}</div>

      {/* Details */}
      <div className="col-span-3 flex flex-row items-center gap-2">
        {activityLog.type === 'CALL' && (
          <IconPhone className="h-3 w-3 fill-none stroke-doubleNickel-gray-500" />
        )}
        {activityLog.type === 'SMS' && (
          <IconMessage className="h-3 w-3 fill-none stroke-doubleNickel-gray-500" />
        )}
        {activityLog.type === 'NOTE' && (
          <IconNote className="h-3 w-3 fill-none stroke-doubleNickel-gray-500" />
        )}
        {(activityLog.type === 'DOCUMENT' ||
          activityLog.type === 'APPLICANT' ||
          activityLog.type === 'APPLICATION') && (
          <IconDocument className="h-3 w-3 fill-none stroke-doubleNickel-gray-500" />
        )}
        {isCall ? <CallTitle activityLog={activityLog} /> : activityLog.title}
      </div>

      {/* Description */}
      <div
        className={cn(
          'col-span-3',
          hovered ? 'line-clamp-none' : 'line-clamp-1'
        )}
      >
        {isCall ? (
          <CallDescription activityLog={activityLog} />
        ) : (
          replaceRecruitersIdWithName(activityLog.content, recruiters)
        )}
      </div>

      {/* Tags */}
      <div className="col-span-2 flex flex-row justify-end gap-1 overflow-auto">
        {isManualRegistered && (
          <Tag size="xs" showCloseButton={false} variant="primary">
            Manual
          </Tag>
        )}
        {isVirtualCall && (
          <Tag size="xs" showCloseButton={false} variant="primary">
            Voice agent
          </Tag>
        )}
      </div>
    </div>
  )
}
